<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-row class="p-2" align-v="center" align-h="between">
        <b-col cols="auto">
          <span class="text-uppercase font-weight-bold mb-0">
            productos y servicios
          </span>
        </b-col>
        <!-- <b-col class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2 bill-button"
            @click="showLastOrdersModal = true"
          >
            Consulta últimos pedidos
          </b-button>
        </b-col> -->
      </b-row>
      <b-table
          ref="table"
        hover
        responsive
        :items="orderLines"
        :fields="columns"
        :no-local-sorting="true"
      >
        <template #cell(product)="data">
          <div class="d-flex">
   
              <validation-observer ref="product">
                <validation-provider
                  v-slot="validationContext"
                  name="producto"
                  rules="required"
                >
                  <small class="text-danger">
                    {{
                      validationContext.errors.length > 0
                        ? "Selecciona un producto"
                        : ""
                    }}
                  </small>
                  <br />
                  <ProductSelect
                    v-model="data.item.product"
                    @option-selected="
                      updateOrderLine({
                        ...data.item,
                        product_id: data.item.product
                          ? data.item.product.id
                          : '',
                      })
                    "
                    style="width: 250px;"
                  />
                </validation-provider>
              </validation-observer>
           
            <span
              v-if="data.item.product"
              class="pl-1 text-danger d-flex align-items-center"
              @click="lastOrdersHandler(data.item.product)"
            >
              <feather-icon
                icon="InfoIcon"
                size="18"
                class="text-indigo mt-1"
              />
            </span>
          </div>
        </template>
        <template #cell(description)="data">
          <b-form-textarea
            v-model="data.item.description"
            name="description"
            placeholder="Descripción"
            @change="updateOrderLine(data.item)"
          />
        </template>
        <template #cell(amount)="data">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="data.item.amount"
              placeholder="importe"
              type="number"
              @change="updateOrderLine(data.item)"
            />
            <b-input-group-append is-text> € </b-input-group-append>
          </b-input-group>
        </template>
        <template #cell(units)="data">
          <b-form-input
            v-model="data.item.units"
            placeholder="Unidades"
            type="number"
            @change="updateOrderLine(data.item)"
          />
        </template>
        <template #cell(total)="data">
          <b-input-group class="input-group-merge">
            <b-form-input
                v-model="data.item.total"
                placeholder="Total"
                type="number"
                @change="updateOrderLine(data.item)"
                @keydown.tab="focusNextDescription(data.index)"
            />
            <b-input-group-append is-text>
              €
            </b-input-group-append>
          </b-input-group>
        </template>
        <template #cell(actions)="data">
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteIconClick(data.item.id)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </template>
      </b-table>
      <b-row
        align-h="around"
        align-v="end"
        no-gutters
        class="p-1 bg-action-blue flex-column"
      >
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold"
            >TOTAL BRUTO:
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{ (order ? order.total_gross : 0) | numberToLocalString }} €
          </span>
        </b-col>
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold">
            TIPO IMPUESTO:
            <TaxesTypeRadioGroup
              v-model="orderTaxType"
              class="d-inline-block"
            />
            <TaxesSelect
              :value="orderTax"
              :type="orderTaxType"
              class="d-inline-block"
              @option-selected="updateOrderTax"
            />
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{ (order ? order.total_tax : 0) | numberToLocalString }} €
          </span>
        </b-col>
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold">
            TOTAL:
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{ (order ? order.total_order : 0) | numberToLocalString }} €
          </span>
        </b-col>
        <b-col cols="auto" class="text-right pt-2">
            <span class="text-dark-gray font-very-small">
            PENDIENTE DE FACTURAR:
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{ (order ? order.pending_billing : 0) | numberToLocalString }} €
          </span>
        </b-col>
      </b-row>
      <b-row class="p-2" align-v="center" align-h="end">
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2 bill-button"
            @click="createEmptyOrderLine"
          >
            + Nuevo producto / servicio
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
    <LastOrdersModal v-model="showLastOrdersModal" :productId="productId" />
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OrdersApi from "@/api/orders-api";
import TaxesSelect from "@/components/tax/select/TaxesSelect.vue";
import TaxesTypeRadioGroup from "@/components/tax/radio-group/TaxesTypeRadioGroup.vue";
import ProductSelect from "@/components/products/select/ProductSelect.vue";
import ApiRestService from "@/api/base-api";
import LastOrdersModal from "@/components/orders/modal/LastOrdersModal.vue";

export default {
  name: "OrderLinesTable",
  components: {
    ProductSelect,
    TaxesSelect,
    TaxesTypeRadioGroup,
    LastOrdersModal,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          label: "PRODUCTO",
          key: "product",
          thStyle: { "width": "300px" },
          sortable: false,
        },
        {
          label: "DESCRIPCIÓN",
          key: "description",
          sortable: false,
        },
        {
          label: "IMPORTE",
          key: "amount",
          sortable: false,
        },
        {
          label: "UNIDADES",
          key: "units",
          sortable: false,
        },
        {
          label: "TOTAL",
          key: "total",
          sortable: false,
          thStyle: { "min-width": "125px" },
          thClass: "text-right px-2",
          tdClass: "text-right px-2",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "75px" },
        },
      ],
      orderTaxType: null,
      loading: false,
      showLastOrdersModal: false,
      productId: '',
    };
  },
  computed: {
    ...mapGetters("orders", {
      order: "getOrder",
      orderLines: "getOrderLines",
    }),
    orderTax() {
      return this.order?.tax;
    },
    // providerId() {
    //   return this.order?.provider?.id;
    // },
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.orderTaxType = this.order?.tax?.type;
      },
    },
  },
  methods: {
    ...mapActions("orders", ["loadOrder", "setOrderTax"]),
    lastOrdersHandler(product) {
      this.productId = product.id
      this.showLastOrdersModal = true
    },
    focusNextDescription(currentIndex) {
      this.$nextTick(() => {
        const nextRow = this.$refs.table.$el.querySelectorAll('tbody tr')[currentIndex + 1];
        if (nextRow) {
          const descriptionInput = nextRow.querySelector('textarea[name="description"]');
          console.log(descriptionInput);
          if (descriptionInput) {
            descriptionInput.focus();
          }
        }
      });
    },
    async updateOrderLine(orderLine) {
      const valid = await this.$refs["product"].validate();
      if (!valid) {
        return;
      }
      this.loading = true;
      try {
        await ApiRestService.edit(
          orderLine.id,
          { ...orderLine, order_id: this.order?.id },
          this.$data.$constants.RESOURCES.RESOURCE_ORDER_LINES,
          false
        );
        await this.loadOrder(this.order?.id);

        this.$toast("Recurso actualizado con éxito.");
      } catch (error) {
        this.handleUpdateError(error);
      }
      this.loading = false;
    },
    async handleUpdateError(error) {
      if (error.response?.data?.error) {
        await this.loadOrder(this.order?.id);
        this.$toast.error(error.response?.data?.error);
      } else {
        console.error(error);
        this.$toast.error(
          "Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde."
        );
      }
    },
    updateOrderTax(tax) {
      this.setOrderTax(tax);
      this.updateOrder();
    },
    async updateOrder() {
      this.loading = true;
      try {
        const order = { ...this.order };
        delete order.lines;
        delete order.documentation;

        await OrdersApi.edit(this.order?.id, order);
        await this.loadOrder(this.order?.id);
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteIconClick(lineId) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará el el producto o servicio.",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await ApiRestService.delete(
          lineId,
          this.$data.$constants.RESOURCES.RESOURCE_ORDER_LINES
        );
        await this.loadOrder(this.order?.id);
      } finally {
        this.loading = false;
      }
    },
    async createEmptyOrderLine() {
      this.loading = true;
      try {
        await ApiRestService.create(
          { order_id: this.order.id },
          this.$data.$constants.RESOURCES.RESOURCE_ORDER_LINES
        );
        await this.loadOrder(this.order?.id);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.total-value-label {
  width: 150px;
}
</style>
