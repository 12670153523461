<template>
  <BaseModal
    v-model="model"
    :title="`Ultimos pedidos ${productCategory} ${productName}`"
    size="xl"
    :loading="loading"
    :showFooter="false"
  >
    <b-row>
      <b-col sm="12">
        <b-table hover responsive :items="orders" :fields="columns">
          <template #cell(units)="data">
            {{ data.item.units | numberToLocalString }} 
          </template>
          <template #cell(amount)="data">
            {{ data.item.amount | numberToLocalString }} €
          </template>
          <template #cell(total)="data">
            {{ data.item.total | numberToLocalString }} €
          </template>
        </b-table>
      </b-col>
    </b-row>
  </BaseModal>
</template>

<script>
import Vue from "vue";
import BaseModal from "@/components/ui/modal/BaseModal.vue";

export default {
  name: "ListOrdersModal",
  components: { BaseModal },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      loading: true,
      columns: [
        // {
        //   label: "Categoria",
        //   key: "product_category",
        //   // thStyle: { "min-width": "150px" },
        //   sortable: false,
        // },
        // {
        //   label: "Producto",
        //   key: "product_name",
        //   // thStyle: { "min-width": "150px" },
        //   sortable: false,
        // },
        {
          label: "Fecha de pedido",
          key: "order_date",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { "width": "200px" },
          sortable: false,
        },
        {
          label: "Provedor",
          key: "order_provider",
          thClass: "text-left",
          tdClass: "text-left",
          sortable: false,
        },
        {
          label: "Coste Unidad",
          key: "amount",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
        },
        {
          label: "Unidades",
          key: "units",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
        },
        {
          label: "Total",
          key: "total",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
        },
      ],
      orders: [],
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.loading = true
        }
        this.$emit("input", value);
      },
    },
    productCategory () {
      return !this.loading ? this.orders[0]?.product_category : ''
    },
    productName () {
      return !this.loading ? this.orders[0]?.product_name : ''
    }
  },
  watch: {
    model(value) {
      if (value) {
        this.loadData();
      }
    },
  },
  methods: {
    async loadData() {
      try {
        const { data } = await Vue.prototype.$http.post(
          `/product-report/last-products-from-provider`,
          {
            product_id: this.productId,
          }
        );
        this.orders = data.data;
        // console.log(response);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
